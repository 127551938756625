import Webcam from 'react-webcam';
import { CameraOptions, useFaceDetection } from 'react-use-face-detection';
import FaceDetection from '@mediapipe/face_detection';
import { Camera } from '@mediapipe/camera_utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Text, Button, ButtonGroup, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, useDisclosure } from '@chakra-ui/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { BsFillCameraVideoFill,BsStopFill } from 'react-icons/bs';
import {IoMdSettings} from 'react-icons/io'
import { RgbaColorPicker, HexColorPicker } from "react-colorful";

const height = window.innerHeight;
const width = window.innerWidth;

const videoConstraints = {
  width:width,
  height:height,
  aspectRatio: height/width
};

const WebcamDemo = () => {
  const mediaRecorderRef = useRef(null);
  const boxRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [videoBlob, setVideoBlob] = useState(null)
  const [fontSize, setFontSize] = useState(25)
  const [boxWidth, setBoxWidth] = useState(300)
  const [boxHeight, setBoxHeight] = useState(200)
  const [backgroundColor, setBackgroundColor] = useState({ r: 0, g: 0, b: 0, a: 0.7 });
  const [fontColor, setFontColor] = useState("#ffffff");
  const [scrollDelayTime, setScrollDelayTime] = useState(3000);
  const [scrollSpeed, setScrollSpeed] = useState(0.05);


  const { webcamRef, boundingBox, isLoading, detected, facesDetected } = useFaceDetection({
    faceDetectionOptions: {
      model: 'short',
    },
    faceDetection: new FaceDetection.FaceDetection({
      locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
    }),
    camera: ({ mediaSrc, onFrame }) =>
      new Camera(mediaSrc, {
        onFrame,
        width,
        height,
      }),
  });

  const delay = ms => new Promise(res => setTimeout(res, ms));

  var scrolldelay;
  async function pageScroll() {
    var elem = document.getElementById("teleprompter")
    if(elem) {
      elem.scrollBy(0,(scrollSpeed*10));
      scrolldelay = setTimeout(pageScroll,10);
    }
  }
  
  const handleStartCaptureClick = React.useCallback(async() => {
    const cameraPermission = await navigator.permissions.query({ name: "camera" })
    if(cameraPermission.state == "granted") {
      boxRef.current.scrollTo(0,0);
      setCapturing(true);
      pageScroll()
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: "video/webm"
      });
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );
      mediaRecorderRef.current.start();
      await delay(scrollDelayTime); 
    } else {
      alert("Please allow access to the camera and the microphone.")
    }
    
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  useEffect(() => {
    if(recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm"
      });
      const url = URL.createObjectURL(blob);
      setVideoBlob(url)
    }
  }, [recordedChunks])
  
  
  const handleDownload = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm"
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = `faceprompter.webm`;
      a.click();
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);

  var ce = document.querySelector('[contenteditable]')
  if(ce) {
    ce.addEventListener('paste', function (e) {
      e.preventDefault()
      e.target.innerText = e.clipboardData.getData("text/plain")
    })
  }
  

  return (
    <div style={{display:"flex", alignItems:'center', justifyContent:'center', overflow:'hidden'}}>
      <div style={{ width, height, position: 'relative', overflow:'hidden' }}>
        {new Array(boundingBox[0] ? (boundingBox[0]):([])).map((box, index) => (
          <div
            contentEditable
            ref={boxRef}
            data-text="Enter text here"
            id={"teleprompter"}
            key={`${index + 1}`}
            style={{
              // border: '4px solid white',
              backgroundColor:`rgba(${backgroundColor.r},${backgroundColor.g},${backgroundColor.b},${backgroundColor.a})`,
              padding:10,
              position: 'absolute',
              top: `${(box.yCenter-(Math.abs((boxHeight/height)-box.height)/2)) * 100}%`,
              right: `${(box.xCenter-(Math.abs((boxWidth/width)-box.width)/2)) * 100}%`,
              // top: `${(box.yCenter) * 100}%`,
              // left: `${(box.xCenter) * 100}%`,
              width: boxWidth+"px",
              height: boxHeight+"px",
              zIndex: 1,
              fontSize:fontSize+"px",
              overflowY:'auto',
              overflowX:'hidden',
              color:fontColor,
              transition: "top 0.3s ease, top 0.3s ease, width 1s ease, height 1s ease",
            }}
          ></div>
        ))}
        <Webcam
          audio={true}
          mirrored={true}
          ref={webcamRef}
          onUserMedia={e => console.log(e)}
          onUserMediaError={e => console.log(e)}
          style={{
            height,
            width,
            position: 'absolute',
            objectFit:"cover"
          }}
          videoConstraints={videoConstraints}
        />
      </div>

      <Box style={{position:"absolute", bottom:20}}>
        {capturing ? (
        <Button colorScheme={"red"} leftIcon={<BsStopFill />} onClick={handleStopCaptureClick}>Stop recording</Button>
        ) : (
          <Button leftIcon={<BsFillCameraVideoFill />} onClick={() => {handleStartCaptureClick()}}>Start recording</Button>
        )}

        <SettingsButton
        scrollSpeed={scrollSpeed} setScrollSpeed={setScrollSpeed}
        scrollDelayTime={scrollDelayTime} setScrollDelayTime={setScrollDelayTime}
        fontColor={fontColor} setFontColor={setFontColor}
        backgroundColor={backgroundColor} setBackgroundColor={setBackgroundColor}
        boxWidth={boxWidth} setBoxWidth={setBoxWidth}
        boxHeight={boxHeight} setBoxHeight={setBoxHeight}
        fontSize={fontSize} setFontSize={setFontSize} />
      </Box>
      
      
      <Modal
       closeOnOverlayClick={false}
       isOpen={videoBlob ? (true):(false)} onClose={() => {setVideoBlob(null);setRecordedChunks([]);onClose()}}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Preview</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <video src={videoBlob} height="400" width="500" controls></video>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme={"red"} variant='ghost' mr={3} onClick={() => {setVideoBlob(null);setRecordedChunks([]);onClose()}}>
              Discard
            </Button>
            <Button colorScheme={"green"} onClick={handleDownload}>Download</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      
    </div>
  );

};

function SettingsButton({scrollSpeed, setScrollSpeed, scrollDelayTime, setScrollDelayTime, fontColor, setFontColor, backgroundColor, setBackgroundColor, fontSize, setFontSize, boxHeight, setBoxHeight, boxWidth, setBoxWidth}) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return(
    <>
    <Button marginLeft={"5px"} leftIcon={<IoMdSettings />} onClick={onOpen} colorScheme={"blue"}>
      Settings
    </Button>
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Settings</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box marginBottom={"10px"}>
            <Text>Font size:</Text>
            <NumberInput value={fontSize} onChange={e => setFontSize(e)}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
          <Box marginBottom={"10px"}>
            <Text>Font colour:</Text>
            <HexColorPicker color={fontColor} onChange={setFontColor} />
          </Box>
          <Box marginBottom={"10px"}>
            <Text>Prompt's width:</Text>
            <NumberInput  value={boxWidth} onChange={e => setBoxWidth(e)} >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
          <Box marginBottom={"10px"}>
            <Text>Prompt's height:</Text>
            <NumberInput value={boxHeight} onChange={e => setBoxHeight(e)}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
          <Box marginBottom={"10px"}>
            <Text>Prompt's background colour:</Text>
            <RgbaColorPicker color={backgroundColor} onChange={setBackgroundColor} />
          </Box>
          <Box marginBottom={"10px"}>
            <Text>{"Delay before scroll starts (in ms):"}</Text>
            <NumberInput value={scrollDelayTime} onChange={e => setScrollDelayTime(e)}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
          <Box marginBottom={"10px"}>
            <Text>{"Scroll speed (per ms):"}</Text>
            <NumberInput value={scrollSpeed} onChange={e => setScrollSpeed(e)}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme='blue' onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    </>
  )
}


export default WebcamDemo;